<script setup>
import HiddenInput from "~/components/atoms/HiddenInput.vue";

const emit = defineEmits(['update:modelValue', 'focusout', 'focusin'])

const props = defineProps({
  modelValue: String,
  name: String,
  focused: Boolean,
  disabled: {
    type: Boolean,
    default: false
  },
  type: {
    type: String,
    default: 'text'
  }
})

const updateValue = (event) => {
  const target = event.target
  emit('update:modelValue', target.value);
};
</script>

<template>
  <HiddenInput :disabled="disabled" :name="name" @input="updateValue" @focusout="$emit('focusout',$event)" @focusin="$emit('focusin',$event)"
               :type="type" :focused="props.focused" v-model="props.modelValue"/>
</template>
