<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <path
        d="M6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11Z"
        stroke="#BF2828" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6 4V6" stroke="#BF2828" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6 8H6.005" stroke="#BF2828" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'ErrorIcon'
}
</script>
