<script setup lang="ts">

</script>

<template>
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2925_39570)">
      <path
          d="M10.7192 5.52447L6.12417 10.1195C5.56125 10.6824 4.79776 10.9986 4.00167 10.9986C3.20558 10.9986 2.44209 10.6824 1.87917 10.1195C1.31625 9.55655 1 8.79306 1 7.99697C1 7.20088 1.31625 6.43739 1.87917 5.87447L6.47417 1.27947C6.84945 0.90419 7.35844 0.693359 7.88917 0.693359C8.4199 0.693359 8.92889 0.90419 9.30417 1.27947C9.67945 1.65475 9.89028 2.16374 9.89028 2.69447C9.89028 3.2252 9.67945 3.73419 9.30417 4.10947L4.70417 8.70447C4.51653 8.89211 4.26203 8.99753 3.99667 8.99753C3.7313 8.99753 3.47681 8.89211 3.28917 8.70447C3.10153 8.51683 2.99611 8.26234 2.99611 7.99697C2.99611 7.73161 3.10153 7.47711 3.28917 7.28947L7.53417 3.04947"
          stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_2925_39570">
        <rect width="12" height="12" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<style scoped lang="scss">

</style>
